import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IImage} from 'app/blocks/model/image.model';
import {IProduct} from 'app/blocks/model/product.model';

export interface ICreateProduct extends IEntityAction {
    entity?: IProduct;
    uploadPhotos?: IImage[];
}

export interface IUpdateProduct extends IEntityAction {
    entity?: IProduct;
    uploadPhotos?: IImage[];
    deletedPhotos?: IImage[];
}

export class CreateProduct implements ICreateProduct {
    static readonly type = '[Product] Create Product';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IProduct,
        public uploadPhotos?: IImage[],
        meta?: ActionMeta
    ) {}
}

export class UpdateProduct implements IUpdateProduct {
    static readonly type = '[Product] Update Product';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IProduct,
        public uploadPhotos?: IImage[],
        public deletedPhotos?: IImage[],
        meta?: ActionMeta
    ) {}
}
